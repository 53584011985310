<template>
  <v-dialog
    :value="value"
    max-width="600"
    transition="dialog-transition"
    @input="$emit('input', $event)"
  >
    <!-- @keydown.esc="cancel" is removed as an exception per MON-1387 -->
    <v-card>
      <v-card-title primary-title v-text="$t('autoInvest.strategyActivation')"/>
      <v-card-text>
        <v-row>
          <v-col>
            <i18n path="onboarding.sms.smsSentTo">
              <template #phone>
                <v-chip small v-text="$auth.user().phone"/>
              </template>
            </i18n>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <label v-if="smsCode.error" class="red" v-text="$t('errors.codeIsNotCorrect')"/>
            <div class="sms-field">
              <v-text-field
                v-model="smsCode.value"
                outlined
                dense
                :label="$t('onboarding.sms.confirmationCode')"
                autocomplete="one-time-code"
                counter="4"
                maxlength="4"
              />
            </div>
          </v-col>
          <v-col cols="12" md="9">
            <div class="multiple-buttons">
              <v-btn :loading="loading" dark @click="confirmSmsCode" v-text="$t('onboarding.sms.submitCode')"/>
              <v-btn :loading="loadingResend" text class="ml-2" @click="newSmsCode" v-text="$t('onboarding.sms.noSms')"/>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-btn text @click="cancel" v-text="$t('autoInvest.cancel')"/>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "ConfirmActivation",
  props: {
    strategy: { type: Object, required: true },
    value: { type: Boolean, default: false},
  },
  data() {
    return {
      loading: false,
      loadingResend: false,
      smsCode: {
        visible: false,
        error: false,
        value: "",
        counter: 0,
      },
    }
  },
  computed: {
    investment_account() {
      return this.$store.state.account
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.newSmsCode()
        }
      },
    },
  },
  methods: {
    newSmsCode() {
      this.loadingResend = true
      this.$http
        .post(`autoinvest_strategies/${this.strategy.id}/create_code/`, {
        })
        .then(() => {
          this.loadingResend = false
          this.$store.commit("pushMessage", {
            icon: "mdi-check",
            text: this.$t('onboarding.sms.verificationCodeHasBeenSent'),
          })
          this.smsCode = { visible: true, value: "" }
        })
        .catch((err) => {
          this.loadingResend = false
          this.$store.commit("pushMessage", {
            icon: "mdi-alert",
            text: this.$t(`onboarding.sms.${err.response.data.code}`),
          })
        })
    },
    confirmSmsCode() {
      this.loading = true
      this.$http
        .post(`autoinvest_strategies/${this.strategy.id}/activate/`, {
          code: this.smsCode.value,
        })
        .then(() => {
          this.loading = false
          this.$emit('closeSmsDialog', true)
        })
        .catch((err) => {
          this.loading = false
          this.$store.commit("pushMessage", {
            icon: "mdi-alert",
            text: this.$t(`onboarding.sms.${err.response.data.code}`),
          })
        })
    },
    cancel() {
      this.$emit('closeSmsDialog', false)
    },
  },
})
</script>
