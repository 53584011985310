<template>
  <div>
    <v-btn block dark
           :loading="loadingActivity"
           :color="strategyStatusButton().color"
           @click="activateStrategy"
    >
      {{ strategyStatusButton().label }}
    </v-btn>
    <ConfirmActivation v-model="smsDialog" :strategy="strategy" @closeSmsDialog="closeSmsDialog"/>
  </div>
</template>

<script>
import ConfirmActivation from "../components/ConfirmActivation.vue"
import Vue from 'vue'

export default Vue.extend({
  name: "AutoInvestStrategyStatusButton",
  components: {
    ConfirmActivation,
  },
  props: {
    strategy: { type: Object, required: true },
  },
  data() {
    return {
      smsDialog: false,
      loadingActivity: false,
    }
  },
  methods: {
    activateStrategy() { // mimics structure of strategyStatusButton function
      if (this.$auth.user().verification === 'approved') {
        if (this.strategy.is_active) {
          this.loadingActivity = true
          this.$http.patch(`/autoinvest_strategies/${this.strategy.id}/`, { is_active: false })
            .then((response) => {
              this.loadingActivity = false
              this.$emit('updateStrategy', { is_active: response.data.is_active })
            }).catch(() => {
              this.loadingActivity = false
            })
        } else {
          this.smsDialog = !this.strategy.is_active
        }
      } else {
        this.$router.push("/register")
      }
    },
    closeSmsDialog(val) {
      this.smsDialog = false
      this.$emit('updateStrategy', { is_active: val })
    },
    strategyStatusButton() {
      if (this.$auth.user().verification === 'approved') {
        if (this.strategy.is_active) {
          return { label: this.$t('common.stop'), color: 'warning' }
        } else {
          return { label: this.$t('common.activate'), color: 'success' }
        }
      } else {
        return { label: this.$t('autoInvest.verifyYourself'), color: 'warning' }
      }
    },
  },
})
</script>
