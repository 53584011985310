<template>
  <v-col cols="12">
    <v-card elevation="2">
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ strategy.strategy_name }}
            <v-tooltip v-if="strategy.strategy_name == strategy.id.split('-')[0]" slot="append-outer" right max-width="400">
              <template #activator="{ on }">
                <v-icon slot="activator" color="primary" dark class="mb-1 ml-1" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span v-text="$t('autoInvest.autogeneratedStrategyNameDescription')"/>
            </v-tooltip>
          </v-col>
          <v-col cols="1" class="priorityNumber d-flex justify-end">
            <v-skeleton-loader v-if="loadingPriorities" type="chip" class="mr-n1"/>
            <template v-else>{{ `#${strategy.priority + 1}` }}</template>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="1" class="d-flex flex-column reorderingColumn">
            <v-btn fab small text class="q-pa-1" :disabled="noUp" @click="$emit('move', 'up')"><v-icon dark x-large>mdi-menu-up</v-icon></v-btn>

            <v-spacer/>
            <v-icon large>mdi-dots-grid</v-icon>
            <v-spacer/>

            <v-btn fab small text class="q-pa-1" :disabled="noDown" @click="$emit('move', 'down')"><v-icon dark x-large>mdi-menu-down</v-icon></v-btn>
          </v-col>
          <v-col cols="10" lg="8" xl="9" class="d-flex flex-column">
            <v-row align="center">
              <v-col cols="12" lg="6" xl="4" class="pa-0 pa-sm-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="$t('autoInvest.maximumAmountPerInvestment')"/>
                    <v-list-item-title><BaseMoney :amount="strategy.maximum_amount_per_investment"/></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" lg="6" xl="8" class="pa-0 pa-sm-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="$t('autoInvest.investedTarget')"/>
                    <v-list-item-title><BaseMoney :amount="strategy.currently_invested_amount"/> / <BaseMoney :amount="strategy.target_portfolio_size"/></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" lg="6" xl="4" class="pa-0 pa-sm-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ $t('autoInvest.investorWeighedInterestRate') }}
                      <v-tooltip bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span v-text="$t('autoInvest.investorWeighedInterestRateTooltip')"/>
                      </v-tooltip>
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      <BasePercent v-if="strategy.weighed_interest" :amount="strategy.weighed_interest"/>
                      <template v-else>–</template>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" lg="6" xl="4" class="pa-0 pa-sm-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="$t('autoInvest.strategyEarnings')"/>
                    <v-list-item-title><BaseMoney :amount="strategy.total_earnings"/></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" lg="6" xl="4" class="pa-0 pa-sm-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="$t('autoInvest.createdAt')"/>
                    <v-list-item-title><BaseDate :date="strategy.created_at" popup="right"/></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3" xl="2" class="pl-0" align="center" justify="center">
            <v-row>
              <v-col class="pl-0">
                <v-btn block dark @click="$emit('viewStrategy', strategy.id)" v-text="$t('autoInvest.view')"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-0">
                <AutoInvestStrategyStatusButton :strategy="strategy" @updateStrategy="$emit('updateStrategy', strategy.id, $event)"/>
              </v-col>
            </v-row>
            <v-row v-if="testButton">
              <v-col class="pl-0">
                <v-btn block dark @click="$emit('applyStrategy', strategy.id)" v-text="$t('autoInvest.invest')"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { VUE_APP_ENVIRONMENT } from "@/config"
import Vue from 'vue'
import AutoInvestStrategyStatusButton from "./AutoInvestStrategyStatusButton.vue"

export default Vue.extend({
  name: "AutoInvestStrategyCard",
  components: {
    AutoInvestStrategyStatusButton,
  },
  props: {
    strategy: { type: Object, required: true },
    loadingPriorities: { type: Boolean, default: false },
    noUp: { type: Boolean, default: false },
    noDown: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    testButton() {
      return VUE_APP_ENVIRONMENT === 'development' || VUE_APP_ENVIRONMENT === 'testing'
    },
  },
  methods: {
  },
})
</script>

<style scoped>
.v-card__title {
  min-height: 5rem;
}
p {
  margin-bottom: 0.5rem;
}
.priorityNumber {
  padding-left: 0px;
  padding-right: 0.5rem;
}
.reorderingColumn {
  max-width: 4rem
}

.v-skeleton-loader::v-deep .v-skeleton-loader__chip {
  width: 2rem !important;
  height: 2rem !important;
}
</style>
