<template>
  <Container :title="$t('autoInvest.autoInvest')" class="autoinvest">
    <v-row class="mb-2">
      <v-col cols="12" md="6" lg="4">
        <v-card elevation="2">
          <v-card-title v-text="$t('autoInvest.custom')"/>
          <v-card-text v-text="$t('autoInvest.customAbout')"/>
          <v-card-actions class="justify-end">
            <v-btn dark class="mx-0" to="/autoinvest/essential" v-text="$t('autoInvest.startInvesting')"/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card elevation="2" disabled>
          <v-card-title>
            {{ $t('autoInvest.highYield') }}
            <v-spacer/>
            <span class="subtitle-2 text-uppercase" v-text="$t('common.comingSoon')"/>
          </v-card-title>
          <v-card-text v-text="$t('autoInvest.highYieldAbout')"/>
          <v-card-actions class="justify-end">
            <v-btn dark class="mx-0" v-text="$t('autoInvest.startInvesting')"/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card elevation="2" disabled>
          <v-card-title>
            {{ $t('autoInvest.lowRisk') }}
            <v-spacer/>
            <span class="subtitle-2 text-uppercase" v-text="$t('common.comingSoon')"/>
          </v-card-title>
          <v-card-text v-text="$t('autoInvest.lowRiskAbout')"/>
          <v-card-actions class="justify-end">
            <v-btn dark class="mx-0" v-text="$t('autoInvest.startInvesting')"/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-if="items.length || loading" flat>
      <v-card-title class="px-0" v-text="$t('autoInvest.existingStrategies')"/>
      <v-card-text v-if="!loading" class="pa-0">
        <draggable
          v-model="items"
          :animation="200"
          chosen-class="dragged"
          ghost-class="ghost"
          handle=".mdi-dots-grid"
          @sort="setPriorities"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group type="transition" :name="!dragging ? 'flip-list' : null" tag="v-row">
            <AutoInvestStrategyCard
              v-for="(item, index) in items"
              :key="item.id"
              :strategy="item"
              :loading-priorities="loadingPriorities"
              :style="{ 'z-index': +(item === swapping) }"
              :no-up="index === 0"
              :no-down="index === items.length - 1"
              @applyStrategy="applyStrategy"
              @viewStrategy="viewStrategy($event)"
              @move="move(item, $event)"
              @updateStrategy="updateStrategy"
            />
          </transition-group>
        </draggable>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
          type="table-heading, article, actions"
        />
      </v-card-text>
    </v-card>
  </Container>
</template>

<script>
import Vue from 'vue'
import AutoInvestStrategyCard from '../components/AutoInvestStrategyCard.vue'
import draggable from 'vuedraggable'

/* eslint-disable vue/match-component-file-name */
import { VRow } from 'vuetify/lib' // This is needed, because v-row element is not native to Vue, but comes with Vuetify ->
import {EventBus} from "@/wsupdate/eventbus"
Vue.component("VRow", VRow)        // and vue-draggable library cannot use v-row unless specifically imported
/* eslint-enable vue/match-component-file-name */

export default Vue.extend({
  name: "AutoInvest",
  components: { AutoInvestStrategyCard, draggable },
  data() {
    return {
      loading:            false,
      page:               1,
      size:               100,
      count:              0,
      items:              [],

      loadingPriorities:  false,
      dragging:           false,

      // `swapping` thing is here simply to know which element we're moving
      // with buttons, so that we can set its z-index.
      // XXX `swapping` will remain even after moving, but that doesn't seem to affect anything.
      swapping:           null,
    }
  },
  computed: {
    account() {
      return this.$store.state.account
    },
    accountId() {
      return this.$store.state.account.id
    },
    headers() {
      return [
        { text: this.$t('autoInvest.strategyName'),               value: 'strategy_name',                 sortable: false },
        { text: this.$t('autoInvest.isActive'),                   value: 'is_active',                     sortable: false },
        { text: this.$t('autoInvest.investorWeighedInterestRate'), value: 'weighed_interest',             sortable: false, align: 'right' },
        { text: this.$t('autoInvest.strategyEarnings'),           value: 'total_earnings',                sortable: false, align: 'right' },
        { text: this.$t('autoInvest.maximumAmountPerInvestment'), value: 'maximum_amount_per_investment', sortable: false, align: 'right' },
        { text: this.$t('autoInvest.investedTarget'),             value: 'target_portfolio_size',         sortable: false, align: 'right' },
        { text: this.$t('autoInvest.createdDate'),                value: 'created_at',                    sortable: false },
        { text: '',                                               value: 'actions',                       sortable: false, align: 'center' },
        { text: '',                                               value: 'preview',                       sortable: false, align: 'center' },
      ]
    },
  },
  watch: {
    page() { this.fetch() },
    size() { this.fetch() },
    accountId: {
      immediate: true,
      handler() { this.fetch() },
    },
  },
  mounted() {
    EventBus.$on('update-auto-invest', this.fetch)
  },
  activated() {
    this.fetch()
  },
  unmounted() {
    EventBus.$off('update-auto-invest', this.fetch)
  },
  methods: {
    fetch() {
      this.loading = true
      this.$http.get(`/autoinvest_strategies/?page=${this.page}&size=${this.size}&investment_account=${this.accountId}`)
        .then((response) => {
          this.loading = false
          this.items = response.data.results
          this.count = response.data.count
        })
        .catch(() => {
          this.loading = false
        })
    },
    applyStrategy(id) {
      this.loading = true
      this.$http.post(`/autoinvest_strategies/${id}/apply_autoinvest_strategy/`)
        .then((response) => {
          this.loading = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-party-popper',
            text: this.$t('autoInvest.successfullyInvested'),
          })
          response.data.results  // TODO: use this
          this.$auth.fetch()
          this.fetch()
        })
        .catch(() => {
          this.loading = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-party-popper',
            text: this.$t('autoInvest.cantInvest'),
          })
        })
    },
    viewStrategy(strategyId) {
      this.$router.push(`/autoinvest-strategy/${strategyId}`)
    },
    updateStrategy(strategyId, data) {
      Object.assign(this.items.find(s => s.id === strategyId), data)
    },
    setPriorities() {
      // TODO ideally we should set loading states and refetch, but that creates some UX issues

      // goddamn Chrome is glitching when we change anything in the DOM of an animated element, so
      // we have to wait until the animation is finished (no issue in Firefox)… ugly, but at this
      // point I don't know any alternative (and I have tried many things!)
      const animationPromise = new Promise((res) => setTimeout(() => res(), 650))

      this.loadingPriorities = true
      this.$http.put(`/autoinvest_strategies/set_priorities/?investment_account=${this.accountId}`, { 'strategies': this.items.map((strategy) => strategy.id) })
        .then((response) => {
          animationPromise.then(() => {
            if (this.loadingPriorities) {
              this.loadingPriorities = false
            }
          })
          for (const item of this.items) {
            item.priority = response.data[item.id]
          }
        })
        .catch(() => {
          this.loadingPriorities = false
        })
    },
    move(strategy, direction) {
      const index = this.items.findIndex((el) => strategy.id === el.id)
      const shift = { 'up': -1, 'down': +1 }[direction] || 0
      this.items.splice(index, 1, this.items.splice(index + shift, 1, this.items[index])[0])
      this.swapping = strategy
      this.setPriorities()
    },
  },
})
</script>

<style>
.autoinvest .v-card__text {
  min-height: 8rem;
}
</style>

<style scoped>
.row-pointer::v-deep tbody tr :hover {
  cursor: pointer;
}

.dragged {
  border: black;
}

.ghost {
  opacity: 0.1;
}

.flip-list-move {
  transition: transform 0.4s;
}
</style>
